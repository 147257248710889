import { Injectable, OnDestroy, inject } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LinkService implements OnDestroy {
  private readonly router = inject(Router);
  private readonly meta = inject(Meta);

  private routeListener?: Subscription;

  /**
   * Start listening on NavigationEnd router events
   */
  public startRouteListener(): void {
    this.routeListener = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      let url = '';
      const urlTree = this.router.parseUrl(this.router.url);

      if (urlTree.root.hasChildren()) {
        const segments = urlTree.root.children['primary'].segments;

        if (segments && segments.length > 0) {
          url = segments.map((segment) => segment.path).join('/');
        }
      }

      this.meta.updateTag({ url: `https://webed.dev/${url}` });

      this.meta.updateTag({
        rel: 'canonical',
        href: `https://webed.dev/${url}`,
      });
    });
  }

  /**
   * Destroy route listener when service is destroyed
   */
  ngOnDestroy(): void {
    this.routeListener?.unsubscribe();
  }
}

export declare type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};

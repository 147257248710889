import { MetaDefinition } from '@angular/platform-browser';
import { Routes } from '@angular/router';

const COMMON_METAS: MetaDefinition = {
  image: 'https://webed.hu/assets/fb-share.jpg',
  type: 'website',
};
export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./common/page/home/home.component').then((m) => m.HomePageComponent),
    data: {
      seo: {
        title: `Webed Developers`,
        meta: {
          description: ``,
          ...COMMON_METAS,
        } as MetaDefinition,
      },
    },
  },
  {
    path: 'hello',
    loadComponent: () => import('./common/page/hello/hello.component').then((m) => m.HelloPageComponent),
    data: {
      seo: {
        title: `Hello @ Webed`,
        meta: {
          description: ``,
          ...COMMON_METAS,
        } as MetaDefinition,
      },
    },
  },
  /* Not exist */
  {
    path: '404',
    loadComponent: () =>
      import('./common/page/error/error-not-found/error-not-found.component').then((m) => m.ErrorNotFoundComponent),
    data: {
      seo: {
        title: `Webed Developers`,
        meta: {
          description: ``,
          ...COMMON_METAS,
        } as MetaDefinition,
      },
    },
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

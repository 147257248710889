import { provideHttpClient, withFetch } from '@angular/common/http';
import { APP_ID, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { appRoutes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideIonicAngular({
      backButtonIcon: 'arrow-back',
      backButtonText: '',
      backButtonDefaultHref: '/',
      mode: 'ios',
    }),

    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: APP_ID, useValue: 'webedApp' },

    importProvidersFrom(FontAwesomeModule),

    provideHttpClient(withFetch()), //Add this line

    provideRouter(
      appRoutes
      //withDebugTracing(), withRouterConfig({ paramsInheritanceStrategy: 'always' })
    ),

    // @angular/ssr module in Angular v17 does not support web components, and there are many unresolved issues with Hydration
    // provideClientHydration(),
  ],
};

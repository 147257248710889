import { Component, LOCALE_ID, OnInit, inject } from '@angular/core';

import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { LinkService } from './common/service/link.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [IonRouterOutlet, IonApp],
})
export class AppComponent implements OnInit {
  private linkService = inject(LinkService);
  protected localeId = inject(LOCALE_ID);

  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
    library.addIcons(faFacebookF, faInstagram, faLinkedin, faXTwitter);
  }

  ngOnInit(): void {
    this.linkService.startRouteListener();
  }
}
